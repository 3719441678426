import React from "react"
import SecondaryBlogCard from "../components/BlogCards/SecondaryBlogCard"
import { LatestResourceItem } from "../styledComponents/styledLatestResources";
import {
  StyledRelatedResources,
  RelatedResourceList,
  RelatedResourceItem,
} from "../styledComponents/styledRelatedResources"


function truncate(str, n){
  return (str.length > n) ? str.substr(0, n-1) + '&hellip;' : str;
}

const LatestResources = ({ relatedPosts}) => {
  // str = post.excerpt
  
  
  return (
    <>
      
          {relatedPosts.nodes.map(post => (
            <LatestResourceItem key={post.id}>
              <SecondaryBlogCard
                title={post.title}
                featuredImage={post.featuredImage}
                uri={post.uri}
                excerpt={truncate(post.excerpt, 120)}
              />
            </LatestResourceItem>
          ))}      
    </>
  )
}

export default LatestResources