import React from "react"
import { graphql } from "gatsby"
import parse, { domToReact } from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  Article,
  ArticleHeader,
  ArticleDate,
  ArticleSection,
  ArticleAuthorInfo,
} from "../styledComponents/article"
import RelatedResources from "../components/RelatedResources"
import SEO from "../components/seo"
import {
  StyledRelatedResources,
  RelatedResourceList
} from "../styledComponents/styledRelatedResources"
import { LatestResourceList, StyledLatestResources } from "../styledComponents/styledLatestResources"
import LatestResources from "../components/LatestResources"
import ShareTools from "../components/socialLinks"
import TagCloud from "../components/TagCloud"
import LayoutNoStripe from "../components/layoutNoStripe"
import { EventListSection } from "../styledComponents/eventList"
import EventList from "../components/EventList"
import Events from "../fixtures/workshops/all"
import { SectionHeading } from "../styledComponents/section"

const Heading = ({ level = 1, location, children, offScreen}) => (
  <SectionHeading as={`h${level}`} level={level} location={location} offScreen={offScreen}>
    {children}
  </SectionHeading>
)

const BlogPostTemplate = ({
  data: { post, relatedPostsByTag, latestPosts },
  location,
}) => {
  const featuredImage = {
    publicURL: post.featuredImage?.node?.localFile?.publicURL,
    imageData: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
    description: post.featuredImage?.node?.description || "",
  }
  let allevents = Events
  const shareURL = `https://${process.env.GATSBY_DOMAIN_NAME}` + post.uri
  return (
    <LayoutNoStripe displayNavBorder location={location} pageType="blog-post">
      <SEO
        title={post.title}
        description={post.excerpt.replace('<p>', '').replace('</p>', '')}
        image={featuredImage.publicURL}
        url={post.uri}
      />

      <Article
        className="resource-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <ArticleHeader>
          <h1 itemProp="headline">{parse(post.title)}</h1>
          <ArticleDate pubdate dateTime={post.dateGmt}><span className="publishedon">Published on</span> {post.date}</ArticleDate>
          <ArticleAuthorInfo className="author"><span>Published by Karina Grant</span> </ArticleAuthorInfo>
          <ShareTools shareTitle="Share this on" pageLocation={shareURL} postTitle={parse(post.title)} />
        </ArticleHeader>

        {!!post.content && (
          <ArticleSection itemProp="articleBody">
            {parse(post.content)}
            <ShareTools shareTitle="Share this on" pageLocation={shareURL} postTitle={post.title} />
          </ArticleSection>
        )}

        <StyledLatestResources>
          <TagCloud type="single" />
          <h2>Latest Blogs</h2>
          <LatestResourceList>
            {(latestPosts.nodes.length && (
              <LatestResources relatedPosts={latestPosts} />
            )) ||
              ""}
          </LatestResourceList>
        </StyledLatestResources>

        {(relatedPostsByTag.nodes.length !== 0 && (
      <StyledRelatedResources >
        <h2>Related Blogs</h2>
        <RelatedResourceList>
        {(relatedPostsByTag.nodes.length && (
          <RelatedResources relatedPosts={relatedPostsByTag} />
        )) ||
          ""}
        </RelatedResourceList>
      </StyledRelatedResources>
        ))}
        
      </Article>

      <EventListSection className="event-list">
        <Heading level={1}>Upcoming workshops</Heading>       
        <EventList workshops={allevents} eventType="all"/>
      </EventListSection>
    </LayoutNoStripe>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $tagName: String!
    $id: String!
  ) {

    relatedPostsByTag: allWpPost(
      sort: { fields: [date], order: DESC }
      limit: 4
      skip: 0
      filter: {
        tags: { nodes: { elemMatch: { name: { eq: $tagName } } } }
        id: { nin: [$id] }
      }
    ) {
      nodes {
        excerpt
        uri
        id
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        tags {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  breakpoints: [320, 720, 1024, 1600]
                )
              }
            }
          }
        }
      }
    }

    latestPosts: allWpPost(
      sort: { fields: [date], order: DESC }
      limit: 7
      skip: 0
      filter: {
        id: { nin: [$id] }
      }
    ) {
      nodes {
        excerpt
        uri
        id
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        tags {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  breakpoints: [320, 720, 1024, 1600]
                )
              }
            }
          }
        }
      }
    }
    # selecting the current post by id
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      uri
      date(formatString: "MMMM DD, YYYY")
      dateGmt

      featuredImage {
        node {
          altText
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 975
                quality: 100
                placeholder: TRACED_SVG
                breakpoints: [320, 720, 1024, 1600]
              )
            }
          }
        }
      }

      tags {
        nodes {
          name
          id
          uri
        }
      }
    }
  }
`
