import styled from "styled-components"
import { baseGridStyles } from "./base"
import breakpoints from "./breakpoints"
import { Section } from "../styledComponents/section"
import { bold18, bold20, bold24, bold28 } from "./typography"

const StyledTagCloud = styled.div`
  max-width: 130.4rem;
  margin: 2.4rem auto;
  ${props => props.type === 'single' && 'grid-column:span  4;'}
  h2 {
    background: none;
    margin-top: 0;
    position: absolute;
    top: -50000px;
  }
  ul {
    margin-top: 0;
    padding-left: 0;
    display: flex;
    flex-direction: revert;
    flex-wrap: wrap; 
    justify-content: ${props => props.type === 'single' ? 'center' : 'space-around'};
    @media screen and (min-width: ${breakpoints.md}px) {
        margin-top: 4rem;
    }
  }
  ul li {
    display: inline;
    margin: 0 0.8rem;
    &:first-of-type{ 
        margin-left: 0;
    }

    a {
        padding: 1.6rem 0 ;
        border-bottom: 0;
        ${bold18}
        &.tag-healing-stories, 
        &.tag-health {
            ${bold20}
        }
        &.tag-quantum-touch,
        &.tag-videos {
            ${bold28}
        }
        &.tag-talks-media {
            ${bold24}
        }
    }
  }
`


export {
    StyledTagCloud
}