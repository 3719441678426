import React from "react"
import {
  StyledSecondaryBlogCard,
  ResourceHeader,
  ResourceFigure,
  ResourceFigureCaption,
} from "./StyledSecondaryBlogCard"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser"

const SecondaryBlogCard = props => {
  const { title, featuredImage, uri, excerpt } = props
  const image = {
    imageData: featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: featuredImage?.node?.alt || ``,
    description: featuredImage?.node?.description || "",
  }

  return (
    <StyledSecondaryBlogCard
      itemScope
      itemType="http://schema.org/Article"
    >
      <Link to={uri} itemProp="url">
        {image && image.imageData && (
          <ResourceFigure>
            <GatsbyImage image={image.imageData} alt={image.alt} transformoptions={{ layout: "FULL_WIDTH" }} />
            <ResourceFigureCaption>{image.description}</ResourceFigureCaption>
          </ResourceFigure>
        )}
        <ResourceHeader image itemProp="headline">{parse(title)}</ResourceHeader>
        {excerpt && parse(excerpt)}
      </Link>
    </StyledSecondaryBlogCard>
  );
}

export default SecondaryBlogCard
