import styled from "styled-components"
import { regular14, regular16, semibold16, semibold18 } from "../../styledComponents/typography"
import breakpoints from "../../styledComponents/breakpoints"
import { baseGridStyles } from "../../styledComponents/base"

const StyledSecondaryBlogCard = styled.article`
  a {
    ${baseGridStyles}
    padding: 0;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    align-content: center;
    @media screen and (min-width: ${breakpoints.sm}px) {
      padding: 0;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      padding: 0;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      padding: 0;
    }

    aside & {
      @media screen and (min-width: ${breakpoints.md}px) {
        grid-row-gap: 0.4rem;
      }
      @media screen and (min-width: ${breakpoints.l}px) {
        grid-row-gap: 0.8rem;
      }
    }


    figure {
      grid-column: span 4;
      grid-column: span 1;
      grid-row: span 1;
      @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: span 4;
        grid-column: span 1;
        grid-row: span 2;
      }
      @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: span 4;
        grid-column: span 1;
        grid-row: span 2;
      }
    }
    h3{
      grid-column: 2 / 6;
      margin-left: 0;
      @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: 2 / 8;
      }
      @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 2 / 11;
        ${semibold16}
        ${semibold18}
        font-weight: 700;
      }

      aside & {
        @media screen and (min-width: ${breakpoints.md}px) {
          grid-column: 2 / 13;
        }
        @media screen and (min-width: ${breakpoints.l}px) {
          grid-column: 2 / 12;
        }
      }
    }
    p {
      ${regular14}
      grid-column: 1 / 6;
      padding-bottom: 1.6rem;
      @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: 1 / 8;
        border-bottom: 0px;

      }
      @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 1 / 12;
      }
    }

    // &:last-child{
    //   p {
    //     border-bottom: 0px solid var(--grey-light);
    //   }
    // }

  }
  p {
    margin: 0;
    padding-bottom: 1.6rem;
  }
  h3 {
    ${semibold16}
    color: var(--light-purple-darker);
  }
`

const ResourceWrapper = styled.div`
  
  max-width: calc(100% - (101px + 32px));
`

const ResourceHeader = styled.h3`
  color: var(--grey-darker);
  margin: ${props => props.image ? '0 0 0 1.6rem' : '0'};
  ${regular16}
  color: var(--light-green-darkest);

  a {
    text-decoration: none;
  }
`

const ResourceFigure = styled.figure`
  margin: 0;
  padding: 0;
  display: inline-flex;
  & .gatsby-image-wrapper {
    width: 6.4rem;
    height: 6.4rem;
  }
  @media screen and (min-width: ${breakpoints.sm}px) {
    & .gatsby-image-wrapper {
      width: 8.8rem;
      height: 8.8rem;
    }

    &:first-child {
      margin-left: 0;
      margin-right: 0;
    }
  }
`

const ResourceFigureCaption = styled.figcaption`
  margin: 0;
  padding: 0;
`

export {
  StyledSecondaryBlogCard,
  ResourceWrapper,
  ResourceHeader,
  ResourceFigure,
  ResourceFigureCaption,
}
