import React from "react"
import StyledSocialLinks from "../styledComponents/socialLinks"
import Icon from "./icon"
import {
    analyticClickEvent
} from "../components/googleAnalytics"
import { StyledTagCloud } from "../styledComponents/TagCloud"

const TagCloud = ({ type }) => {
    return (
        <StyledTagCloud type={type}>
            <h2>Blogs tagged with:</h2>
            <ul>
                <li><a href="/blog/tags/healing" className="tag-healing">Healing</a></li>
                <li><a href="/blog/tags/talks-&-media" className="tag-talks-media">Talks &amp; Media</a></li>
                <li><a href="/blog/tags/quantum-touch" className="tag-quantum-touch">Quantum-Touch</a></li>
                <li><a href="/blog/tags/inspiration" className="tag-inspiration">Inspiration</a></li>
                <li><a href="/blog/tags/healing-stories" className="tag-healing-stories">Healing Stories</a></li>
                <li><a href="/blog/tags/video" className="tag-videos">Videos</a></li>
                <li><a href="/blog/tags/health" className="tag-health">Health</a></li>
                <li><a href="/blog/tags/supercharging" className="tag-supercharging">Supercharging</a></li>
            </ul>
        </StyledTagCloud>
    )
}
export default TagCloud
